<template>
  <el-dialog
    title="查看大图"
    :visible.sync="pigImgVisible"
    width="1000px"
    center
    :modal-append-to-body="false"
    :append-to-body="true"
    class="seePigImgPage"
  >
    <img
      :src="pigImgUrl"
      style="max-width:100%;display:block;margin:0 auto;"
    >
    <div class="pigImgName">{{pigImgName}}</div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="pigImgVisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      pigImgVisible: false,
      pigImgUrl: "",
      pigImgName: "",
      deg: "",
    };
  },
  created() {},
  methods: {
    init(url, name) {
      this.pigImgVisible = true;
      this.pigImgUrl = url;
      this.pigImgName = name;
    },
  },
};
</script>
<style lang="less">
.seePigImgPage {
  .el-dialog__body {
    overflow-y: auto;
    height: calc(100vh - 500px);
  }
  .pigImgName {
    text-align: center;
    line-height: 30px;
    font-size: 16px;
  }
}
</style>
